.category-container-selected {
  min-width: 140px;
  background-color: #f6eedc;
  padding: 8px 16px;
  cursor: pointer;
}

.category-container-selected h4 {
  width: 100%;
  text-align: center;
  color: #cf5137;
  font-weight: bold;
  margin: 4px;
}

.category-container {
  min-width: 140px;
  background-color: #cf5137;
  padding: 8px 16px;
  cursor: pointer;
}

.category-container h4 {
  width: 100%;
  text-align: center;
  color: white;
  font-weight: 400;
  margin: 4px;
}
